<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'ProHome',

    metaInfo () {
      return {
        title: this.$t('heroPro.title'),
        titleTemplate: (titleChunk) => {
          return titleChunk ? `${titleChunk} - ${this.$t('meta.titleTemplate')}` : this.$t('meta.titleTemplate')
        },
      }
    },

    extends: View,

    mixins: [
      LoadSections([
        'anonymous/sections/hero-pro',
        'anonymous/sections/pro-features',
        // 'sections/pricing-plan',
        'sections/divider',
        'anonymous/sections/pro-we-help-your-success',
        'sections/contact-us',
        'sections/info',
        // 'sections/social-media',
      ]),
    ],
  }
</script>
